import { useState } from "react";
import { collection, addDoc } from "firebase/firestore";
import { logEvent } from "firebase/analytics";
import { analytics, db } from "./firebaseConfig";

function App() {
  logEvent(analytics, "app_opened");

  function openModal() {
    const modal = document.getElementById("my_modal");
    (modal as HTMLDialogElement)?.showModal();
    logEvent(analytics, "modal_opened", { location: "waitlist_modal" });
  }

  function closeModal() {
    const modal = document.getElementById("my_modal");
    (modal as HTMLDialogElement)?.close();
  }
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // Function to handle email submission
  const handleSaveEmail = async (e: React.FormEvent) => {
    e.preventDefault();
    setSuccess(false);
    logEvent(analytics, "email_try_submit", { email });

    if (!email) {
      setError("Please enter an email.");
      return;
    }

    if (!validateEmail(email)) {
      setError("Please enter a valid email.");
      return;
    }

    try {
      await addDoc(collection(db, "emails"), { email });
      setEmail("");
      setError("");
      setSuccess(true);
      logEvent(analytics, "email_submitted", { email });
    } catch (error) {
      console.error("Error adding email:", error);
      setError("Failed to save email. Please try again.");
    }
  };

  return (
    <div className="md:h-dvh bg-gradient-to-r from-purple-100 to-blue-100 flex flex-col items-center justify-between py-4 px-7">
      {/* Top Bar */}
      <div className="w-full flex justify-between items-center">
        <img src="/yocial-logo3.png" alt="Logo" className="w-28" />
        <button
          onClick={openModal}
          className="btn btn-neutral text-white hidden md:block"
        >
          Sign up to waitlist
        </button>
      </div>

      {/* First Section */}
      <div className="mt-8 md:mt-0 flex flex-col md:flex-row items-center justify-center">
        <div className="h-[calc(100dvh-180px)] mb-16 md:mb-0 md:h-auto flex flex-col justify-between text-center md:text-left md:max-w-[425px] space-y-7">
          <h1 className="text-3xl md:text-5xl font-extrabold text-gray-800">
            Become the social version of{" "}
            <span className="bg-gradient-to-r from-purple-600 to-blue-500 bg-clip-text text-transparent">
              yourself
            </span>
          </h1>
          <p className="text-lg">
            Practice your social skills in a safe environment, build meaningful
            connections, <b>and overcome loneliness.</b>
          </p>
          <div className="flex justify-center space-x-4">
            <button onClick={openModal} className="btn btn-neutral text-white">
              Sign up to waitlist
            </button>
          </div>
          <div className="divider">or contact us directly</div>
          <div className="flex gap-4 justify-center items-center">
            <button
              onClick={() =>
                window.open(
                  "https://www.linkedin.com/in/nicolas-ebner/",
                  "_blank"
                )
              }
              className="btn btn-primary rounded-lg p-0 w-[104px] text-white bg-gradient-to-r from-purple-600 to-purple-500"
            >
              <img src="/linkedin.png" alt="linkedin" className="w-7" />
              Nicolas
            </button>
            <b>&</b>
            <button
              onClick={() =>
                window.open(
                  "https://www.linkedin.com/in/max-rosenblattl/",
                  "_blank"
                )
              }
              className="btn btn-primary rounded-lg p-0 w-[104px] text-white bg-gradient-to-r from-purple-600 to-purple-500"
            >
              <img src="/linkedin.png" alt="linkedin" className="w-7" />
              Max
            </button>
          </div>
        </div>
        <div className="flex flex-col items-center mt-12 md:mt-0 md:ml-20">
          <img src="/PhoneTalk.png" alt="Phone 1" className="w-56" />
          <div className="italic">Practice with AI-Avatars!</div>
        </div>
      </div>

      {/* Footer */}
      <div className="w-full flex justify-center items-center mt-8 space-x-2 text-gray-600">
        <span>Made with ❤️ in Munich, Germany!</span>
      </div>

      {/* Modal */}
      <dialog id="my_modal" className="modal modal-bottom sm:modal-middle">
        <div className="modal-box">
          <button
            onClick={closeModal}
            className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
          >
            ✕
          </button>
          <h3 className="font-bold text-lg">Sign up to waitlist</h3>
          <p className="py-4">Enter your email below to join the waitlist.</p>
          <input
            type="email"
            placeholder="E-Mail"
            className="input input-bordered w-full mb-2"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          {error && <p className="text-red-500 mb-4">{error}</p>}
          {success && (
            <div className="flex flex-col items-center">
              <img
                src="/success.webp"
                alt="Success"
                className="w-3/4 rounded-3xl shadow-lg"
              />
              <p className="text-green-700 my-3 text-2xl font-bold text-center">
                You are on the waitlist!
              </p>
            </div>
          )}
          <div className="modal-action">
            <form method="dialog" onSubmit={handleSaveEmail}>
              <button type="submit" className="btn btn-neutral text-white">
                Save
              </button>
            </form>
          </div>
        </div>
      </dialog>
    </div>
  );
}

export default App;
