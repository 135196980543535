import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyA71J-RgpgIqlyTBmCEjoguHMuQB7PsN04",
  authDomain: "yocial-dev.firebaseapp.com",
  projectId: "yocial-dev",
  storageBucket: "yocial-dev.firebasestorage.app",
  messagingSenderId: "503496408316",
  appId: "1:503496408316:web:9971ca74921f4981cbeb79",
  measurementId: "G-N16ZSC6TF6",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const functions = getFunctions(app);

export { db, analytics, functions };

if (window.location.hostname === "localhost") {
  // connectFirestoreEmulator(db, "localhost", 8080);
  connectFunctionsEmulator(functions, "localhost", 5001);
}
